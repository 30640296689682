@import './variables.scss';

body {
  font-family: 'verdana' !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.flex {
  display: flex;
}

.flex-center {
  justify-content: center;
}

.flex-start {
  justify-content: start;
}

.flex-end {
  justify-content: end;
}

.padd20 {
  padding: 20px;
}

.padd30 {
  padding: 0 30px;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.p-3 {
  padding: 3rem;
}

//margin
.m-3 {
  margin: 3rem;
}

//margin
.mt-1 {
  margin-top: 1px;
}

.mt-2 {
  margin-top: 2px;
}

.mt-3 {
  margin-top: 3px;
}

.mt-4 {
  margin-top: 4px;
}

.mt-5 {
  margin-top: 5px;
}

.mb-1 {
  margin-bottom: 1px;
}

.mb-2 {
  margin-bottom: 2px;
}

.mb-3 {
  margin-bottom: 3px;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mr-3 {
  margin-right: 3px;
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

//height

.height-5 {
  height: 50px;
}

//font-size

.text-normal {
  font-size: 20px;
}

.text-medium {
  font-size: 22px;
}

.text-small {
  font-size: 15px;
}

.text-14 {
  font-size: 14px;
}

.smaller-text {
  font-size: 16px;
}

.text-smaller {
  font-size: 13px;
}

.text-smallest {
  font-size: 12px;
}

.small-text {
  font-size: 10px;
}

.text-md {
  font-size: 18px;
}

.text-big {
  font-size: 30px;
}

.h1sm {
  font-size: 25px;
}

.h1medium {
  font-size: 28px;
}

.h1xl {
  font-size: 55px;
}

.text-smal {
  font-size: 11px;
}

.h1md {
  font-size: 35px;
}

.text-23 {
  font-size: 23px;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.bottom-border {
  border-bottom: 2px solid #d6d6d6;
}

.border-left {
  border-left: 2px solid #d6d6d6;
}

.border-right {
  border-right: 2px solid #d6d6d6;
}

.radius-10 {
  border-radius: 10px;
}

.radius-8 {
  border-radius: 8px;
}

.word-b {
  word-break: break-all;
}

//text align
.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.btn-p {
  color: #ffffff;
  background: linear-gradient(to right, #f58594, #d06070) !important;
  box-shadow: 3px 2px 7px #d060709c;
  border-radius: 50px;
  margin: auto;
  display: block;
  padding: 10px 35px;
}

.btn-primary {
  background-color: $primary-color  !important;
  border-radius: 22px !important;
}

.txt-notification {
  color: #7b7b7b;
  font-size: 9px;
  font-weight: 500;
}

.flex-spacebetween {
  display: flex;
  justify-content: space-between;
}

.eventTitle {
  font-size: 20px;
}

.eventAnchorTag {
  color: #000;
  cursor: pointer;
  text-decoration: none;
}

.eventimageTag {
  width: 100%;
  margin-top: 10px;
  padding-bottom: 0px;

}

.eventimageTagmobile {
  width: 100%;
  margin-top: 20px;
  padding-bottom: 0px;

}

/* Hide scrollbar for IE and Edge */
.hideScrollBar {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}